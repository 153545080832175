import React from 'react';
import { useIntl } from 'react-intl';
import LinkAndAnchor from '../LinkAndAnchor';
import HomeLink from '../HomeLink';
import messages from '../../lib/messages.mjs';

const FashionFinderBreadcrumbs = () => {
  const intl = useIntl();

  return [
    <HomeLink />,
    <LinkAndAnchor
      route="fashion-finder"
    >
      {intl.formatMessage(messages['fashion-finder.title'])}
    </LinkAndAnchor>,
  ];
};

export default FashionFinderBreadcrumbs;
