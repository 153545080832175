import React, { useState, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { ListItemContent, ListItem } from '@fuww/library/src/List/List';
import { List, ListDivider } from '@fuww/library/src/List';
import Headline3 from '@fuww/library/src/Typography/Heading/Headline3';
import { Breadcrumbs } from '@fuww/library/src/Breadcrumb';
import ProfileListFilters from '@fuww/library/src/ProfileListFilters';
import { Grid, GridCell } from '@fuww/library/src/Grid';
import Layout from '../components/Layout';
import Head from '../components/Head';
import crumbs from '../components/FashionFinder/getBreadcrumbs';
import { useSiteContext } from '../components/SiteContext';
import withQuery from '../lib/withQuery';
import debouncedReplaceRoute from '../lib/debouncedReplaceRoute';
import productBrandsConnectionQuery
from '../lib/queries/products/productBrandsConnectionQuery.graphql';
import Hero from '../components/Hero';
import messages from '../lib/messages.mjs';
import withApollo from '../lib/apollo';
import redirectRouteToDomain from '../lib/redirectRouteToDomain';
import LinkAndAnchor from '../components/LinkAndAnchor';
import LoadMore from '../components/LoadMore';
import loadMore from '../lib/queries/loadMore';
import productBrandsConnectionType from '../lib/productBrandsConnectionType';
import replaceRoute from '../lib/replaceRoute';

const FashionFinderList = ({
  data, variables, fetchMore, loading,
}) => {
  const {
    productBrandsConnection: {
      edges,
      pageInfo: {
        hasNextPage,
      },
    },
  } = data;

  return (
    <List>
      {
        edges.map(
          ({ node: { name, profiles } }) => {
            const { page: { slug } } = profiles[0];
            return (
              <Fragment key={name}>
                <LinkAndAnchor
                  route="fashion-finder-profile"
                  params={{ slug }}
                >
                  <ListItem>
                    <ListItemContent
                      title={name}
                    />
                  </ListItem>
                </LinkAndAnchor>
                <ListDivider />
              </Fragment>
            );
          },
        )
      }
      <LoadMore
        hasNextPage={hasNextPage}
        loading={loading}
        fetchMore={
          () => loadMore({ variables })(
            data, 'productBrandsConnection', fetchMore,
          )
        }
      />
    </List>
  );
};

FashionFinderList.propTypes = {
  variables: PropTypes.shape({}).isRequired,
  data: productBrandsConnectionType.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchMore: PropTypes.func.isRequired,
};

const FashionFinderListWithQuery = withQuery(FashionFinderList);

export const FashionFinderPage = () => {
  const { locale } = useSiteContext();
  const intl = useIntl();
  const LIMIT = 16;

  const { query: queryParameters } = useRouter();
  const { search: searchParameter, section } = queryParameters;

  const [search, setSearch] = useState(searchParameter || null);

  return (
    <>
      <Head
        title={
          intl.formatMessage(
            messages['fashion-finder.title'],
          )
        }
        description={
          intl.formatMessage(messages['fashion-finder.description'])
        }
        payload={{ marketId: 8 }}
      />
      <Grid container>
        <GridCell padding="0 16px 16px">
          <Breadcrumbs
            crumbs={crumbs()}
          />
        </GridCell>
        <GridCell padding="16px">
          <Headline3 tag="h1">
            {intl.formatMessage(messages['fashion-finder.title'])}
          </Headline3>
        </GridCell>
      </Grid>
      <ProfileListFilters
        searchId="fashion-finder-search"
        searchLabel="Search brands"
        searchOnChange={({ target: { value } }) => {
          setSearch(value);
          const { search: _search, ...rest } = queryParameters;
          debouncedReplaceRoute(
            'fashion-finder', {
              ...rest,
              ...(value ? { search: value } : {}),
            },
          );
        }}
        searchValue={search}
        searchOnClear={() => {
          setSearch(null);
          const { search: _search, ...rest } = queryParameters;
          replaceRoute('fashion-finder', {
            ...rest,
          });
        }}
        premiumChecked={section !== 'all'}
        premiumOnChange={({ target: { checked } }) => {
          const { section: _section, ...rest } = queryParameters;
          replaceRoute(
            'fashion-finder', {
              ...rest,
              ...(checked ? {} : { section: 'all' }),
            },
          );
        }}
      />
      <FashionFinderListWithQuery
        query={productBrandsConnectionQuery}
        variables={{
          first: LIMIT,
          marketId: 8,
          locales: locale,
          hasProfile: true,
          includeProfile: true,
          search,
          hasPremiumProfile: section !== 'all' || null,
        }}
        errorMessage="Error loading brands"
      />
    </>
  );
};

FashionFinderPage.getLayout = (page) => (
  <Layout header={<Hero />}>
    {page}
  </Layout>
);

FashionFinderPage.getInitialProps = redirectRouteToDomain(
  'fashion-finder',
);

export default withApollo(FashionFinderPage);
