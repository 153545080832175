import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import {
  ListItem as ListItemTextContainer,
} from './index';
import Headline5
  from '../Typography/Heading/Headline5';
import Body2
  from '../Typography/Body/Body2';
import breakpoints from '../Theme/breakpoints';

const listItemPropertyTypes = {
  divider: PropTypes.string,
};

type ListItemProperties = InferProps<typeof listItemPropertyTypes>;

const ListItem = styled.li<ListItemProperties>`
  border-bottom: ${(properties) => properties.divider};
  margin: 0 16px;
  list-style-type: none;

  &:last-of-type {
    border-bottom: none;
  }

  @media (min-width: ${breakpoints.md}px) {
    margin: 0;
  }
`;

ListItem.propTypes = listItemPropertyTypes;

ListItem.defaultProps = {
  divider: '1px solid var(--grey-light)',
};

const listItemContentPropertyTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  imageLeft: PropTypes.node,
  imageRight: PropTypes.node,
  contentClassName: PropTypes.string,
};

type ListItemContentProperties = InferProps<
  typeof listItemContentPropertyTypes
> & {
  contentClassName?: string;
};

const ListItemContent = ({
  title,
  subtitle,
  description,
  imageLeft,
  imageRight,
  contentClassName,
}: ListItemContentProperties) => (
  <ListItemTextContainer
    tag="div"
  >
    {imageLeft}
    <div className={contentClassName}>
      {title
      && (
      <Headline5 tag="h2">
        {title}
      </Headline5>
      )}
      {subtitle
      && (
      <Body2>
        {subtitle}
      </Body2>
      )}
      {description
      && (
      <Body2>
        {description}
      </Body2>
      )}
    </div>
    {imageRight}
  </ListItemTextContainer>
);

ListItemContent.propTypes = listItemContentPropertyTypes;

export { ListItemContent, ListItem };
